<template>
<div>
  <level-bar>{{assetConfig.type}}</level-bar>
  <div class="columns">
    <div class="column">
      <!-- METRICS -->
      <div class="columns">
        <div class="column">
          <div class="box fixed-height">
          <nav class="level">
            <alert-display v-for="alertId in assetAlerts" :key="alertId"
                      :alertDef="alertFilterDef[alertId]"
                      :ids="alertIds[alertId] || ''"
                      :mode="$route.query.mode"
            ></alert-display>
          </nav>
          </div>
        </div>
        <div class="column">
          <div class="box fixed-height">
          <nav class="level">
            <metric-item :metricData="metric" v-for="(metric,idx) in pageConfig.metrics" :key="idx">
            </metric-item>
          </nav>
          </div>
        </div>
        </div>
    </div>
  </div>
  <!-- MAIN CONTENT -->
  <div class="columns">
    <div class="column is-narrow">
     <intelyt-card>
        <template slot="header">{{assetConfig.group}} Summary</template>
        <template slot="controls">
          <!-- <span style="display:flex; align-content:center; padding-left:5px;" v-if="assetMap === 'dynamic'">
            <label>
              <input type="search" id="search-input" class="form-control" placeholder="Search Summary"
                  :value="searchInput"
                  @input="(e) => {this.searchInput = e.target.value}">
            </label>
             <span class="icon-search is-right" style="padding-top:5px;">
                <base-icon name="search" size="18" color="grey"/>
              </span>
          </span> -->
        </template>
        <base-table
              :data="summaryDashData"
              :columns="summaryColumns"
              :options="summaryOptions"
              :searchText="searchInput"
              :searching="false">
        </base-table>
      </intelyt-card>
      <intelyt-card v-if="pageConfig.warehouseSummaryCols && pageConfig.warehouseSummaryCols.length > 0">
        <template slot="header">Zone Summary</template>
        <template slot="controls">
        </template>
        <base-table
              :data="warehouseDashData"
              :columns="warehouseColumns"
              :options="summaryOptions"
              :searchText="searchInput"
              :searching="false">
        </base-table>
      </intelyt-card>
    </div>
    <div class="column">
      <intelyt-card>
        <template slot="header">{{assetConfig.type}} List</template>
        <template slot="controls">
          <span v-for="filter in getFilters" :key="filter.col">
            <span class="tag is-primary boldtext" v-if="filter.active === true && filter.val.length > 0">
              {{filter.name}}: {{getFilterValue(filter)}}
              <button class="delete is-small" @click="clearFilter(filter)"></button>
            </span>
          &nbsp;
          </span>
          <span class="icon-padding" style="padding-right:5px;">
            <!-- <base-icon v-if="showAddIcon" name="add" @click="openModal('addAsset')" v-tooltip="'Create Asset'"/> -->
            <base-icon v-if="tableControls.includes('findAsset')" name="binoculars" @click="openModal('shipmentFind')" v-tooltip="'Find Active Assets'" class="icon-padding"/>
            <base-icon v-if="tableControls.includes('filterTable')" name="filter" @click="openModal('filterTable')" v-tooltip="'Filter Assets'" class="icon-padding"/>
            <base-icon v-if="tableControls.includes('downloadCSV')" name="download_csv"  @click="downloadCSV()" v-tooltip="'Download CSV'" class="icon-padding"/>
            <base-icon v-if="tableControls.includes('print')" name="print"  @click="tablePrint()" v-tooltip="'Print'" class="icon-padding"/>
          </span>
        </template>
        <base-table
          :data="assetData"
          :columns="columns"
          :options="tableOptions"
          ref="assetList"
          :filter="filters.getActive()"
        >
        </base-table>
      </intelyt-card>
    </div>
    <div class="column is-narrow">
      <intelyt-card>
        <template slot="header">Deployed Assets</template>
        <template slot="controls">
          <span class="icon-padding" v-if="mapExpanded" style="padding-right:5px;">
            <base-icon name="collapse" @click="toggleMode()" v-tooltip="'Shrink Map'"/>
          </span>
          <span class="icon-padding" v-if="!mapExpanded" style="padding-right:5px;">
            <base-icon name="expand" @click="toggleMode()" v-tooltip="'Expand Map'"/>
          </span>
        </template>
        <intelyt-map
           :markers="assetMarkers"
           :clusterZoomCutoff="10"
           :mapWidth="mapWidthCalc"
           @markerDblClick="logMrkDblClick">
        </intelyt-map>
        <intelyt-map-legend
          v-if="markers.length > 0"
          :markers="markers"
          :activeMarkers="activeMarkers"
        ></intelyt-map-legend>
      </intelyt-card>
    </div>
  </div>
  <shipment-find-modal
    :isActive="modalIsActive.shipmentFind"
    :tabConfig="tabConfig"
    :redirectPath="`${$route.query.mode}-detail`"
    :title="`Find Active ${itemName}s`"
    :resultFilter="resultFilterAsset()"
    :cfgType="pageConfig.configType"
    :redirect="true"
    v-on:close="closeModal('shipmentFind')"
  >
  </shipment-find-modal>
  <filter-table-modal
      :filters="filters.getAll()"
      :isActive="modalIsActive.filterTable"
      :modalTitle="'Filter Shipments'"
      v-on:close="closeModal('filterTable')"
      v-on:reset="filtersReset()"
    >
    </filter-table-modal>
    <add-edit-modal
      :title="pageConfig.addShipmentForm.label"
      :formElements="pageConfig.addShipmentForm.fields"
      :defaults="formdefaults"
      :width="1000"
      :isActive="modalIsActive.addEdit"
      :callback="createShipmentCallback"
      v-on:close="closeModal('addEdit')"
    >
    </add-edit-modal>
    <send-command-modal
      :messageObj="cmdModal.messageObj"
      :isActive="modalIsActive.sendCommand"
      :actionButtonText="cmdModal.button"
      :commandArgs="{callbackFn: cmdModal.callback, params: cmdModal.params}"
      v-on:close="closeModal('sendCommand')"
    >
    </send-command-modal>
</div>
</template>

<script>
import AlertDisplay from '@/components/AlertDisplay'
import IntelytMapLegend from '@/components/IntelytMapLegend'
import MetricItem from '@/components/MetricItem'
import {
  alertStringFmt,
  batteryIcon,
  batteryTooltip,
  capitalize,
  deviceLink,
  lastAssetUpdateIcon,
  parseHumidity,
  parseTemperature,
  shipIcons,
  shortDayTime,
  lastCommsFmt
} from '@/store/formatters.js'
import {getMapWidth, getShpmntDeviceType, lookupProjectIdHelper, lookupProjectMsgHelper, getMessageObject, stringTemplateParser, getDateFormat} from '@/store/helpers'
import {modalWindowManager, tableFilterManager} from '@/store/mixins'
import {mapActions, mapState} from 'vuex'
import router from '@/router'
import moment from 'moment-timezone'
import ShipmentFindModal from '@/modals/ShipmentFindModal'
import FilterTableModal from '@/modals/FilterTableModal'
import AddEditModal from '@/modals/AddEditModal'

export default {
  components: {
    AlertDisplay,
    FilterTableModal,
    IntelytMapLegend,
    MetricItem,
    AddEditModal,
    ShipmentFindModal
  },
  computed: {
    alertFilterDef: function () {
      const alertsObj = this.$store.state.configuration.alertEvent
      const defaultDefs = this.$store.state.configuration.defaultAlertFilterDef
      const alertFilterDef = {}
      // Loop the available assetAlerts for the user and create the alert definition object
      this.pageConfig.assetAlerts.forEach((alertId) => {
        const alertObj = alertsObj[alertId]
        // If the alert object have shipmentPath use it, else use the defaultDefs.shipmentPath
        const path = alertObj.assetPath || defaultDefs.assetPath
        const filterStates = alertObj.filterStates || defaultDefs.filterStates
        alertFilterDef[alertId] = Object.assign({path, filterStates}, defaultDefs, alertObj)
      })
      return alertFilterDef
    },
    alertIds: function () {
      return this.$store.state.alerts.ids[this.pageConfig.configType] || {}
    },
    allDetailColumns: function () {
      /* Formatter to calculate the date difference between now() and createDate */
      const activeDateFrmt = function (createDate) {
        return Math.max(moment().diff(moment(createDate), 'days'), 1)
      }
      const mode = this.$route.query.mode
      const assetLink = function (row) {
        // return path and query string for router link
        return {path: 'detail', query: { id: row.guid, mode }}
      }
      const showProjLookup = function (row) {
        // Used to determine if lookup icon should be visible
        return row.clientShipmentId === 'None'
      }
      const vm = this
      const lastComms = function (guid) {
        const shpmnt = vm.$store.state.shipments.all[guid]
        return lastCommsFmt(shpmnt, vm.pageConfig.lastCommsHours)
      }
      const lastCommsExpt = function (guid) {
        /* Format lastComms for CSV export. */
        const shpmnt = vm.$store.state.shipments.all[guid]
        return shortDayTime(shpmnt.lastComms)
      }
      const listControls = [
        {
          icon: 'search',
          callback: this.lookupProjectId,
          tooltip: 'Lookup Project D',
          show: showProjLookup
        }
      ]
      /* Get users timezone for display on list */
      const tmzn = this.$store.state.user.timezone
        ? this.$store.state.user.timezone
        : moment.tz.guess()
      const timezone = moment().tz(tmzn).format('z')
      const deviceType = this.deviceType
      // this.$store.dispatch('devices/initialize', {deviceType})
      let getDeviceLink = function (mac) {
        return deviceLink(vm.$store.state, mac, deviceType)
      }
      // Battery Icon formatter
      const batteryIconFrmt = function (voltage) {
        return batteryIcon(voltage, deviceType)
      }
      // Battery tooltip implementation
      const battTooltipFrmt = function (row) {
        return batteryTooltip(vm.$store.state, {voltage: row.battery, type: deviceType})
      }
      // Returns the device charging status
      const chargeStatus = function (status) {
        return parseInt(status) > 0 ? 'Yes' : 'No'
      }
      const allDetailColumns = {
        activationDate: {label: 'Activation Date', id: 'createDate', formatter: shortDayTime, exportFmt: shortDayTime},
        battIcon: {label: 'Battery', id: 'battery', formatter: batteryIconFrmt, tooltip: battTooltipFrmt},
        crate: {label: 'Crate ID', id: 'customer', link: assetLink},
        crateNum: {label: 'Crate #', id: 'customInt'},
        chargeStatus: {label: 'Charging', id: 'customInt', formatter: chargeStatus},
        ctrl: {label: '', id: 'guid', controls: listControls},
        days: {label: 'Days Active', id: 'createDate', formatter: activeDateFrmt, exportFmt: activeDateFrmt},
        dest: {label: 'Destination', id: 'destination'},
        device: {label: 'Device', id: 'macId', formatter: getDeviceLink},
        humid: {label: 'Humidity', id: 'statusNotes', formatter: parseHumidity, exportFmt: parseHumidity},
        icons: {label: 'Alerts', id: 'securityStateStr', formatter: shipIcons, exportFmt: alertStringFmt},
        id: {label: 'Asset ID', id: 'clientShipmentId', link: assetLink},
        lastComms: {label: 'Last Comms', id: 'guid', formatter: lastComms, exportFmt: lastCommsExpt, ignoreSorting: true},
        locDest: {label: 'Location', id: 'destination'},
        locOrig: {label: 'Location', id: 'origin'},
        lstUpdt: {label: `Last Update (${timezone})`, id: 'lastUpdate', formatter: shortDayTime, exportFmt: shortDayTime},
        lstUpdtIcon: {label: 'Last Update', id: 'lastUpdate', formatter: lastAssetUpdateIcon, exportFmt: shortDayTime},
        origin: {label: 'Origin', id: 'origin'},
        part: {label: 'Part Type', id: 'customer'},
        project: {label: 'Project', id: 'clientShipmentId'},
        serialNumber: {label: 'Serial Number', id: 'clientShipmentId', link: assetLink},
        status: {label: 'Status', id: 'shippingState', formatter: this.assetState},
        temp: {label: 'Temp', id: 'statusNotes', formatter: parseTemperature, exportFmt: parseTemperature},
        tote: {label: 'Tote ID', id: 'notes', link: assetLink},
        contractNumber: {label: 'Contract Number', id: 'notes'},
        zone: {label: 'Zone', id: 'zoneId'}
      }
      return allDetailColumns
    },
    allSummaryCols: function () {
      // const stageCommandDuration = this.pageConfig.stageCommandDuration * 1000
      // /** Formatter to show the control icons to stage shipment command/Create Shipment modal */
      const showCreateControl = function (row) {
        // console.log('show showCreateControl', row)
        // Used to determine if controls to make shipment should be visible
        // note: row.config is concatenated string of shippingState values
        return row.config && row.config.includes('2') && !row.config.includes('5') && !row.config.includes('6')
      }
      // const showStageControls = function (row) {
      //   /* Show the control to stage shipment if sufficient time has passed (stageCommandDuration) or shipment has never been staged. */
      //   const currentTime = parseInt(moment().format('x'))
      //   /* Allow to execute the stage command, if the lastCommandTime === 0(not stored in DB) or
      //      the currentTime is greater than the stored command time + duration time */
      //   const showStageIcon = row.lastCommandTime === 0 || currentTime > (parseInt(moment.unix(row.lastCommandTime).format('x')) + stageCommandDuration)
      //   // Used to determine if controls to stage shipment should be visible
      //   return false
      // }

      const summaryControls = [
        {
          icon: 'add',
          callback: this.createShipment,
          tooltip: 'Create Shipment',
          show: showCreateControl
        }
      ]
      /* Formatter to add link to add hyperlink to value */
      const filterLink = function (val) {
        return `<a>${val}</a>`
      }
      const allSummaryCols = {
        // {name: 'Part Number', id: 'number', formatter: filterPartNumber, eventclick: this.navigate},
        connect: {label: 'Size', id: 'config'},
        origin: {label: 'Origin', id: 'origin'},
        order: {label: '#', id: 'config'},
        partFilter: {label: 'Part Type', id: 'name', formatter: filterLink, eventclick: this.updateTableFilter},
        asmlwFilter: {label: 'ASML 12NC', id: 'name', formatter: filterLink, eventclick: this.updateTableFilter},
        partlabel: {label: 'Part Name', id: 'name'},
        partNum: {label: 'Part Number', id: 'number', formatter: filterLink, eventclick: this.updateTableFilter},
        location: {label: 'Location', id: 'number'},
        count: {label: 'Count', id: 'count'},
        summaryControls: {label: 'Stage/Ship', id: 'icon', controls: summaryControls}
        // {name: '', id: 'icons', colId: 'icons', controls: controlIcons}
      }
      return allSummaryCols
    },
    // New table cols to filter asset records for the zone/warehouse
    allWarehouseCols: function () {
      /* Formatter to add link to add hyperlink to value */
      const filterLink = function (val) {
        return `<a>${val}</a>`
      }
      const allWarehouseCols = {
        warehouseFilter: {label: 'Zone', id: 'name', formatter: filterLink, eventclick: this.updateWarehouseFilter},
        count: {label: 'Count', id: 'count'}
      }
      return allWarehouseCols
    },
    assetAlerts: function () {
      return this.pageConfig.assetAlerts
    },
    assetConfig: function () {
      return this.$store.state.configuration.shipmentConfigTypes[this.pageConfig.configType]
    },
    assetData: function () {
      // Filter the assets based on the page config type
      const assets = Object.values(this.$store.state.shipments.all).filter(shp => parseInt(shp.configType) === this.pageConfig.configType)
      // Update the marker list on table data update
      this.setAssetMarkers()
      return assets
    },
    assetMap: function () {
      return this.pageConfig.assetMap
    },
    columns: function () {
      return this.getTableColumns()
    },
    filterConfig: function () {
      return this.pageConfig.tableFilters
    },
    itemName: function () {
      // return header for list - use mode + 's'
      const mode = this.$route.query.mode
      return capitalize(mode)
    },
    isProvisioner: function () {
      const isCpnyAdmin = this.$store.state.user.roles.includes('Company Administrator')
      const isProvisioner = this.$store.state.user.roles.includes('Provisioner')
      return isCpnyAdmin || isProvisioner
    },
    mapWidthCalc: function () {
      /* Calculate map width (as percent of window, based on page mapExpanded. If in summary
       * mode, the map expands, in detail mode, it shrinks to leave more room for table */
      const mapRatio = this.mapExpanded ? 0.4 : 0.3
      return getMapWidth(this.$parent.windowWidth, mapRatio)
    },
    markers: function () {
      return this.pageConfig.map.markers
    },
    /* showAddIcon: function () {
      return this.isProvisioner && this.tableControls.includes('addAsset')
    }, */
    // pagesize: function () {
    //   let ps = window.innerWidth
    //   return ps
    // },
    summaryColumns: function () {
      return this.getTableColumns('summary')
    },
    summaryDashData: function () {
      /* Get data to populate summary table. This elemets (rows) of this table will either be
       * static or dynamic.
       * If static, there will be one row for each element in the configuraiton (assetTypes) and a
       *    count that is 0 or greater.
       * If dynamic, there will be one row foreach returned value in the shipment.ids object.  This
       *   data requres a map for transformation to form used in the table.
       */
      const shp = this.$store.state.shipments.ids
      function staticMapFn (itm) {
        // console.log('staticMapFn', itm)
        const count = shp[itm.value] ? shp[itm.value].split(',').length : 0
        const ids = shp[itm.value] ? shp[itm.value] : ''
        return {name: itm.name, number: itm.value, config: itm.config, count, ids, active: false}
      }
      function dynamicMapFn (itm) {
        // console.log('dynamicMapFn', itm)
        const [name, number, config, guids, stageCommandTime] = itm[0].split(':')
        const lastCommandTime = stageCommandTime ? parseInt(stageCommandTime) : 0
        const count = itm[1].split(',').length
        return {name, number, config, count, ids: itm[1], active: false, guids: guids, lastCommandTime}
      }
      if (this.assetMap === 'dynamic') {
        return Object.entries(shp).map(dynamicMapFn)
        // return this.$store.state.company.assetTypes.map(staticMapFn)
      }
      // Default - use static map
      return this.$store.state.company.assetTypes.filter(type => type.active).map(staticMapFn)
    },
    summaryOptions: function () {
      return this.pageConfig.summaryOptions
    },
    tabConfig: function () {
      return this.pageConfig.findShipmentFields
    },
    tableControls: function () {
      return this.pageConfig.tableControls
    },
    tableOptions: function () {
      return this.pageConfig.tableOptions
    },
    // Zone/warehouse cols for the table
    warehouseColumns: function () {
      return this.getTableColumns('warehouse')
    },
    // Zone/warehouse filter records
    warehouseDashData: function () {
      const whIdsData = this.$store.state.shipments.whs || {}
      if (Object.values(whIdsData).length === 0) return []
      const mapFn = function (row) {
        const [name, ids] = row
        return {name, ids, count: ids.split(',').length}
      }
      return Object.entries(whIdsData).map(mapFn)
    },
    ...mapState({
      tags: 'tags',
      user: 'user'
    })
  },
  created () {
    // this.$store.dispatch('shipments/updateShipmentList', {cfgType: 5})
    this.$store.dispatch('geofences/initializeRouteList')
    // If warehouse summary cols found, then load the warehouse summary table data
    const loadWarehouseFilter = this.pageConfig.warehouseSummaryCols ? true : false
    this.$store.dispatch('alerts/loadIds', {cfgType: this.pageConfig.configType})
    this.$store.dispatch('shipments/loadIds', {cfgType: this.pageConfig.configType, loadWarehouseFilter})
    this.setFilterbyQuery()
    // const existingFilters = this.$store.state.filterList[this.filterKey]
    let filters = []
    // If the page config have default filters, then apply the default filters
    if (this.pageConfig.defaultFilters && this.pageConfig.defaultFilters.length > 0) filters = this.pageConfig.defaultFilters
    this.$store.dispatch('updateFilterState', [this.filterKey, filters])
    this.$store.dispatch('waypoints/initialize')
    // get the messages for the assetDashboard page
    this.$store.dispatch('getPageMessage', 'dashboard')
  },
  data () {
    const mode = this.$route.query.mode
    const pageKey = `${mode}Dashboard`
    /* Set page values from configuration for page setting and asset type settings */
    const pageConfig = this.$store.state.configuration.pageOptions[pageKey]
    const activeMarkers = {}
    // Loop through the marker item and set the active and hide properties for activeMarkers
    const pageMarkers = pageConfig.map && pageConfig.map.markers ? pageConfig.map.markers : []
    pageMarkers.forEach((mrk) => {
      activeMarkers[mrk] = {active: true, hide: true}
    })
    const deviceType = getShpmntDeviceType(this.$store.state, pageConfig.configType)
    this.$store.dispatch('devices/initialize', {deviceType})
    // Data object...
    return {
      activeMarkers,
      assetMarkers: [],
      cmdModal: {},
      deviceType,
      // columns: pageConfig.detailCols.map(col => allDetailColumns[col]),
      filterKey: pageKey,
      formdefaults: {},
      mapExpanded: this.$route.query.expanded || false,
      modalIsActive: {
        // createShipment: false,
        shipmentFind: false,
        filterTable: false,
        addAsset: false,
        addEdit: false,
        sendCommand: false
      },
      origin: {},
      pageConfig,
      path: [],
      pathOptions: {},
      projectId: '',
      searchInput: ''
      // selectedShippingState: [1, 2, 3, 4, 5, 6, 7],
    }
  },
  methods: {
    assetState: function (x) {
      return this.$store.state.stateDefs.asset[x]
    },
    logMrkDblClick (mrk) {
      router.push({path: 'detail', query: { id: mrk.guid, mode: this.$route.query.mode }})
    },
    // NOTE: OLD WAY TO DEAL WITH EVENTS....instead have map emit events and listen
    // on component
    // markerFunction (mrk) {
    //   console.log('mrk single click', mrk)
    //   // router.push({path: 'asset-detail', query: { id: mrk.guid }})
    // },
    closeAssetModal () {
      this['shipments/updateShipmentList']()
      this.assetModalIsActive = false
    },
    createShipmentCallback: function (formValues) {
      const vm = this
      return new Promise(function (resolve, reject) {
        vm.$store.dispatch('shipments/createShipment', formValues).then((response) => {
          resolve(response)
        }).catch(e => {
          // skipcq: JS-0002.  Allow console.error
          console.error('ERROR: createShipmentCallback error ', e)
          // const retVal = {'status': 417, 'message': `${args.error}:<br> ${e}`}
          reject(e)
        })
      })
    },
    resultFilterAsset () {
      const filterResult = (shp) => {
        // return shp.transitMode.includes('Asset')
        // Filter the shipment for the page config type
        return parseInt(shp.configType) === this.pageConfig.configType
      }
      return filterResult
    },
    downloadCSV: function () {
      this.$refs.assetList.generateCSV(`${this.$route.query.mode}-list.csv`)
    },
    /**
     * getTableColumns - Returns the table columns for the summary and detail tables
     * The column configurations will process the each key and overwrites the label
     * value, if found in configuration
     */
    getTableColumns (type) {
      let sourceCols = []
      let configColumns = []
      if (type === 'summary') {
        sourceCols = this.allSummaryCols
        configColumns = this.mapExpanded ? this.pageConfig.minSummaryCols : this.pageConfig.maxSummaryCols
      } else if (type === 'warehouse') {
        sourceCols = this.allWarehouseCols
        configColumns = this.pageConfig.warehouseSummaryCols
      } else {
        sourceCols = this.allDetailColumns
        configColumns = this.mapExpanded ? this.pageConfig.minDetailCols : this.pageConfig.maxDetailCols
      }
      // Loop the each item. If the item is an object, then overwrite the label value from configuration
      // Else return the item based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = sourceCols[key.id]
          column.label = key.label
          return column
        } else {
          return sourceCols[key]
        }
      }
      return configColumns.map(mapFn)
    },
    // Replace the activeMarkers data property with updated marker values
    setActiveMarkers: function (activeMarkers) {
      this.activeMarkers = activeMarkers
    },
    setAssetMarkers: function () {
      const activeMarkers = this.activeMarkers
      const dateFmt = `${getDateFormat()} HH:mm z`
      // const activeMarkers1 = this.activeMarkers
      // const activeMarkers = JSON.parse(JSON.stringify(activeMarkers1))
      // activeMarkers = Object.assign({}, activeMarkers)
      let assetMarkers = []
      let markerIndex = 0
      /* filter out any saved or decomissioned shipments that are on the list */
      const activeAssetFilter = function (shp) {
        const shpState = parseInt(shp.shippingState)
        return shpState > 1 && shpState < 5
      }
      /* apply any filters that are currently active on the table view */
      const filters = this.getFilters.filter(flt => flt.active)
      const allShipments = Object.values(this.$store.state.shipments.all).filter(activeAssetFilter)
      const filteredShipments = allShipments.filter((item) => filters.every((field) => item[field.col].includes(field.val)))
      const tmzn = this.$store.state.user.timezone || moment.tz.guess()
      for (let shpmnt of filteredShipments) {
        let iconVal = ''
        let markerMsg = `Asset ID: ${shpmnt.clientShipmentId}<br/>Asset Type: ${shpmnt.customer}`
        // If markers have only one item, then return 'currentLocationIcon' by default
        // If markers have more than one item, the use the customer value to define the icon value
        const vehicleIcon = shpmnt.customer.replace(/ /g, '')
        const validVehicleIcon = this.markers.length > 1 && shpmnt.customer && this.markers.includes(vehicleIcon) && activeMarkers[vehicleIcon].active
        if (this.markers.length === 1) {
          iconVal = 'currentLocationIcon'
          if (activeMarkers.currentLocation.hide) activeMarkers.currentLocation.hide = false
        } else if (validVehicleIcon) {
          iconVal = vehicleIcon
          if (activeMarkers[iconVal].hide) activeMarkers[iconVal].hide = false
          markerMsg += `<br/>Last Update: ${moment(shpmnt.lastComms, 'ddd MMM D HH:mm:ss YYYY').tz(tmzn).format(dateFmt)}`
        }
        // If icon vlue not found, then skip the record
        if (!iconVal || (iconVal === 'currentLocationIcon' && !activeMarkers.currentLocation.active)) continue
        let coords = shpmnt.originCoords
        coords = shpmnt.destinationCoords && Number.parseFloat(shpmnt.destinationCoords.lat) !== 0.0 ? {
          lat: Number.parseFloat(shpmnt.destinationCoords.lat),
          lng: Number.parseFloat(shpmnt.destinationCoords.lng)
        } : {
          lat: Number.parseFloat(shpmnt.latitude),
          lng: Number.parseFloat(shpmnt.longitude)
        }
        let markerObj = {
          id: markerIndex,
          guid: shpmnt.guid,
          position: coords,
          infoText: markerMsg,
          icon: iconVal
        }
        assetMarkers.push(markerObj)
        markerIndex += 1
      }
      // this.setActiveMarkers(activeMarkers)
      this.assetMarkers = assetMarkers
    },
    tablePrint: function () {
      this.$refs.assetList.generatePrint('listingtable')
    },
    toggleMode () {
      // console.log('Toggle: ', this.mapExpanded)
      this.mapExpanded = !this.mapExpanded
    },
    updateTableFilter (row) {
      this.$store.dispatch('shipments/updateShipmentList', {cfgType: this.pageConfig.configType, ids: row.ids})
      // Get the filters configured for the page and replace the dynamic values
      const filterPayload = stringTemplateParser(JSON.stringify(this.pageConfig.summaryFilter || {}), row)
      this.$store.dispatch('updateFilterState', [this.filterKey, JSON.parse(filterPayload), true])
    },
    updateWarehouseFilter (row) {
      this.$store.dispatch('shipments/updateShipmentList', {cfgType: this.pageConfig.configType, ids: row.ids})
      // Get the filters configured for the page and replace the dynamic values
      const filterPayload = stringTemplateParser(JSON.stringify(this.pageConfig.warehouseFilter || {}), row)
      this.$store.dispatch('updateFilterState', [this.filterKey, JSON.parse(filterPayload), true])
    },
    createShipment (row) {
      // console.log('createShipment', row)
      // Load most recent details for the selected assets in this shipment & then open modal
      this.$store.dispatch('shipments/updateShipmentList', {cfgType: this.pageConfig.configType, ids: row.ids})
        .then(() => {
          // Get origin from the first shipment item in the list
          const firstShip = this.$store.state.shipments.all[row.guids.split(',')[0]]
          this.origin = {name: firstShip.origin, latitude: firstShip.latitude, longitude: firstShip.longitude}
          // get guid list and project id....
          const totalShp = this.$store.state.shipments.all
          const guids = row.guids.split(',')
          const availableGuids = guids.filter(id => typeof totalShp[id] !== 'undefined')
          this.projectId = row.name
          this.formdefaults = {
            guidList: availableGuids,
            shipmentId: row.name
          }
          this.openModal('addEdit')
        })
    },
    lookupProjectId: function (row) {
      const mode = this.$route.query.mode
      // Capitalize the first letter
      const shipmentType = mode.charAt(0).toUpperCase() + mode.slice(1)
      const msgObj = lookupProjectMsgHelper(row)
      // getMessageObject will return object/Boolean(false)
      const getMessageObj = getMessageObject(this.$store.state, 'dashboard', 'lookupProjectId', {...msgObj, shipmentType})
      this.cmdModal.messageObj = getMessageObj
      this.cmdModal.callback = lookupProjectIdHelper
      this.cmdModal.params = {row: row}
      this.modalIsActive.sendCommand = true
    },
    ...mapActions([
      'shipments/updateShipmentList'
    ])
  },
  mixins: [modalWindowManager, tableFilterManager],
  mounted () {
    // Load all records if loadAtMounted value is set in page conifg and is truthy.
    if (this.pageConfig.loadAtMounted) {
      this.$store.dispatch('shipments/updateShipmentList', {cfgType: this.pageConfig.configType})
    }
  },
  watch: {
    $route (to) {
      // console.log('Route: ', to, from, this.$route.query.filterStates)
      this.mode = to.query.mode
      this.filterKey = `${this.mode}Dashboard`
      this.pageConfig = this.$store.state.configuration.pageOptions[`${this.mode}Dashboard`]
      const deviceType = getShpmntDeviceType(this.$store.state, this.pageConfig.configType)
      this.deviceType = deviceType
      this.$store.dispatch('devices/initialize', {deviceType})
      this.$store.dispatch('shipments/loadIds', {cfgType: this.pageConfig.configType})
      const activeMarkers = {}
      // Loop through the marker item and set the active and hide properties for activeMarkers
      const pageMarkers = this.pageConfig.map && this.pageConfig.map.markers ? this.pageConfig.map.markers : []
      pageMarkers.forEach((mrk) => {
        activeMarkers[mrk] = {active: true, hide: true}
      })
      this.setActiveMarkers(activeMarkers)
      let filterStates = this.$route.query.filterStates
      if (!this.$route.query.filterStates || this.$route.query.filterStates === '') {
        filterStates = '0,0,0'
      }
      let ids = ''
      if (this.$route.query.ids && this.$route.query.ids !== '') ids = this.$route.query.ids
      this.$store.dispatch('shipments/updateShipmentList', {cfgType: this.pageConfig.configType, filterStates, ids})
      // react to route changes...
      this.setFilterbyQuery()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

.fixed-height {
  height: 80px;
}

</style>
