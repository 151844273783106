<template>
  <div>
    <level-bar>
      {{pagename}}
    </level-bar>
    <div class="columns">
      <div class="column">
        <intelyt-card :reportOptions="reportOptions">
          <template slot="header">Unit Readings {{warehouse.name}}</template>
          <nav class="level">
            <template v-for="(data, i) in unitReadings">
              <div class="level-item has-text-centered" :key="i">
                <div>
                  <p class="heading">{{ data.label }}</p>
                  <p class="title" :style="noBottomMargin">{{ data.value }}</p>
                </div>
              </div>
            </template>
          </nav>
        </intelyt-card>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-quarter">
        <intelyt-card :scrolling="true" :maxHeight="'315px'">
          <template slot="header">Configuration</template>
          <base-table
            ref="warehouseConfig"
            :data="warehouseColumns"
            :fixHeader="true"
            :columns="warehouseConfigColumns"
            :options="warehouseConfigOptions">
          </base-table>
        </intelyt-card>
      </div>
      <div class="column is-three-quarter">
        <base-slider v-on:sliderChange="updateRange" :disabled="isDisabled" :intervals="sliderInterval" :units="sliderUnit"></base-slider>
        <intelyt-card>
          <template slot="header">Data Chart</template>
          <template slot="controls">
            <span class="icon">
              <base-icon name="download_csv" @click="downloadChartData()" v-if="selectedColumns.length !== 0" v-tooltip="'Export Data'" />
            </span>
          </template>
          <base-chart classname='chart-style'
            :options='warehouseChart'
            :showLoadingIcon='false'
            :notMerge='true'
        ></base-chart>
        </intelyt-card>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <intelyt-card :scrolling="true" :maxHeight="'600px'" :maxWidth="window.width">
          <template slot="header">Warehouse Data</template>
          <template slot="controls">
            <span style="display:flex;align-items:center;">
              <base-icon name="download_csv"  @click="downloadWarehouseData()"  v-if="warehouseData.length !== 0" v-tooltip="'Export Data'" class="icon-padding"/>
            </span>
          </template>
          <base-table
            ref="warehouseList"
            :data="warehouseData"
            :columns="warehouseColumns"
            :options="options"
            :fixHeader="true"
            :fixFirstCol="true"
            :isNarrow="isNarrow">
          </base-table>
        </intelyt-card>
      </div>
    </div>
    <report-config-modal
      :isActive="modalIsActive.reportConfig"
      :selectedReport="selectedReport"
      :externalControls="externalControls"
      v-on:generate="previewReport"
      v-on:close="closeModal('reportConfig')"
    >
    </report-config-modal>
    <report-results-modal
      :reportData="reportData"
      :reportSearch="false"
      :fileName="reportFileName"
      :isActive="modalIsActive.reportResults"
      v-on:close="closeModal('reportResults')"
    >
    </report-results-modal>
  </div>
</template>

<script>
import {chartOptionFactory} from '@/store/factories'
import { getDateArr, getDate, isBetween, exportAsCSV, round, getDateFormat } from '@/store/helpers'
import {localStorageMixin, modalWindowManager, sessionManager} from '@/store/mixins'
import moment from 'moment-timezone'
import BaseSlider from '@/components/BaseSlider'

export default {
  components: {
    BaseSlider
  },
  computed: {
    reportOptions: function () {
      const pageConfig = this.$store.state.configuration.pageOptions.DHUnitDetail
      // Detail card options from configuration
      const reportOptions = pageConfig.reports
      if (reportOptions.length === 0) return []
      // const showShockRpt = this.shipment.shock && Object.values(this.shockData).length > 0 // Available if the shipment is shock enabled and have shock records
      // All the available card option listed below. Only the options available in configuration and it's show condition is true will be returned as the output.
      const allReportOptions = {
        warehouseUnitDataExport: {icon: 'report', text: 'Warehouse Unit Data', optionArgs: {callbackFn: this.openReportModal, params: 'warehouseUnitDataExport'}, show: true}
      }
      // Loop the each config item. If the config is an object, then overwrite the text value from configuration
      // Else return the config based on the key value
      const mapFn = function (key) {
        if (typeof key === 'object') {
          let column = allReportOptions[key.name]
          column.text = key.text
          return column
        } else {
          return allReportOptions[key]
        }
      }
      const userReports = this.$store.state.user.reports
      // Get the cardoptions from the list and filter based on the show value
      // const filteredOptions = reportOptions.map(mapFn).filter(col => col.show)
      const filteredOptions = reportOptions.map(mapFn).filter(col => col.show && userReports.includes(col.optionArgs.params))
      return Object.values(filteredOptions)
    },
    selectedReport: function () {
      // Return the selected report object for the selected report key
      const config = {...this.$store.state.reports.all[this.reportKey]}
      config.reportKey = this.reportKey
      return config
    },
    unitReadings: function () {
      if (!this.warehouse.dhUnits[this.$route.query.unitIndex].readings) return []
      let fieldList = this.warehouse.dhUnits[this.$route.query.unitIndex].readings
      const DHUnitData = this.warehouse.dhUnits[this.$route.query.unitIndex].data
      const allFields = {
        avgH: {label: 'Indoor Humidity', value: `${parseFloat(DHUnitData['Avg_H (%)']).toFixed(1)} %`},
        avgT: {label: 'Indoor Temperature', value: `${parseFloat(DHUnitData['Avg_T (F)']).toFixed(1)} F`},
        sysRt: {label: 'System Runtime', value: DHUnitData['Sys Runtime']},
        dhRt: {label: 'DH Runtime', value: DHUnitData['DH Runtime']},
        outAirRh: {label: 'Outside Air RH', value: DHUnitData['Outside Air RH']},
        retAirT: {label: 'Return Air T', value: DHUnitData['Return Air T (F)']},
        supAirRh: {label: 'Supply Air RH', value: DHUnitData['Sys Runtime']},
        supAirT: {label: 'Supply Air T', value: DHUnitData['Supply Air T']},
        supFan: {label: 'SplyFan (%)', value: DHUnitData['SplyFan (%)']}
      }
      return fieldList.map(f => allFields[f])
    },
    warehouse: function () {
      return this.$store.state.warehouses.all[this.$route.query.id]
    },
    warehouseChart: function () { // Assign a chart data
      let title = `Warehouse Chart`
      // this.selectedColumns = Object.entries(this.warehouseColumns).map(col => col[1]).filter(c => parseInt(c.axis) > -1)
      this.setSelectedColumns(Object.entries(this.warehouseColumns).map(col => col[1]).filter(c => parseInt(c.axis) > -1))
      let series = this.selectedColumns.map(r => { return {yAxisIndex: r.axis, data: []} })
      let filteredCols = this.selectedColumns.map(fc => fc.id) // Get selected Axis column id(s)
      let chartColors = this.selectedColumns.map(fc => fc.color) // Get selected Axis color(s)
      filteredCols.forEach((id, indx) => {
        series[indx].data = this.warehouseData.map(row => {
          return [Number(moment(row['cell_0']).format('X') * 1000), row[id]]
        }).sort((a, b) => (a[0] < b[0]) ? 1 : -1) // Get selected column and sort the data then assign to data series
      })
      const options = {yAxis: [{type: 'value'}, {type: 'value'}]} // {yAxis: yAxisFormat, tooltip: 'datetime'}
      series = (series.length === 0) ? [{yAxisIndex: 0, data: []}] : series
      const chartConfig = this.$store.state.configuration.charts
      const tmz = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      return chartOptionFactory(chartConfig, title, series, options, chartColors, tmz)
    },
    warehouseData: function () { // Assign a warehouse list table value from overall csv data (csvFileData) and filter of date range
      let filterDate = this.range
      let filterData = Object.values(this.csvFileData).filter(row => isBetween(filterDate)({timestamp: moment(row['cell_0']).format('X') * 1000, value: row['cell_0'], val: moment(row['cell_0']).format('X')}))
      return filterData
    },
    warehouseUnitDataExportExternalControls: function () {
      const unitIndex = this.$route.query.unitIndex || 0
      const pktType = this.$route.query.pktType
      const msgType = this.$route.query.msgType
      const macId = this.$route.query.id
      const value = `${macId}|${msgType}|${pktType}|${unitIndex}`
      // Returns the default values for the warehouse unit data report form
      return {
        warehouseUnit: {type: 'hidden', value}
      }
    }
  },
  created () {
    this.$store.dispatch('reports/initialize')
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.fetchMessages(moment(this.dateRanges.startDate, 'MM-DD-YY').format('YYYY-MM-DD'), moment(this.dateRanges.endDate, 'MM-DD-YY').format('YYYY-MM-DD 23:59:59'))
  },
  data () {
    const axisName = function (row) { // Assign selected Y-Axis position
      if (row === 0) return 'Left'
      if (row === 1) return 'Right'
      return 'None'
    }
    const showIcon = function () {
      return true
    }
    const axisSelectIcon = {
      icon: 'refresh_square',
      callback: this.toggleAxis,
      tooltip: 'Toggle Y-Axis',
      show: showIcon
    }
    const controlIcons = [axisSelectIcon]
    const colorIndicator = function (row) {
      return `<div class="roundCircle" style="background-color: ${row}"></div>`
    }
    let dateBetweenDays = 30
    let dateOfStartRange = 27
    let end = moment() // Slider end date is current date
    let start = moment().subtract(dateBetweenDays, 'days') // Slider start date
    let sliderDatesInput = moment(end).diff(start, 'days') // get number of date between start and end date
    let sliderValueInput = sliderDatesInput - dateOfStartRange // Assign slider start range
    let warehouseConfigColumnsVal = [
      {label: 'Field', id: 'label'},
      {label: '', id: 'axis', controls: controlIcons},
      {label: 'Y-Axis', id: 'axis', formatter: axisName},
      {label: 'Color', id: 'color', formatter: colorIndicator}
    ]
    const pageConfig = this.$store.state.configuration.pageOptions.DHUnitDetail
    const sliderInterval = [pageConfig.slider.range, pageConfig.slider.start]
    // const warehouse = this.$store.state.warehouses.all[this.$route.query.id]
    return {
      csvFileData: [], // Main csv file data (row > 1)
      colorIndex: 0,
      externalControls: {},
      isDisabled: false,
      isNarrow: true,
      macId: this.$route.query.id,
      noBottomMargin: 'margin-bottom:1px;',
      modalIsActive: {
        reportConfig: false,
        reportResults: false
      },
      options: {
        paginate: true,
        sortable: false,
        perPage: 10,
        sortIndex: 0,
        sortType: 'desc'
      },
      pagename: 'Warehouse',
      reportKey: '',
      reportData: [],
      reportFileName: '',
      slider: {
        dates: getDateArr(sliderDatesInput, end),
        min: 1,
        height: 4,
        width: 450,
        tooltipDir: ['left', 'right'],
        value: getDate(sliderValueInput, end)
      },
      sliderInterval,
      sliderUnit: pageConfig.slider.scale,
      selectedColumns: [],
      warehouseConfigOptions: {
        paginate: false,
        sortable: false
      },
      warehouseColumns: [], // Column of warehouse list
      warehouseConfigColumns: warehouseConfigColumnsVal,
      //  Date filters
      dateRanges: {
        startDate: moment(start).add(dateOfStartRange, 'days').format('M-DD-YY'),
        endDate: moment(end).format('M-DD-YY')
      },
      range: [moment.utc().valueOf(), moment.utc().valueOf()],
      window: {
        width: 0,
        height: 0
      }
    }
  },
  methods: {
    downloadWarehouseData () { // Download warehouse data to CSV format
      const fmtStr = this.$store.state.configuration.csvExportDateFormat
      const mapFN = function (dataRow) {
        // map the date elements to shorter date format by testing them as Moment instance.  Then join into comma delimited list
        // const fmtStr = 'M/D/YY HH:mm'
        return Object.values(dataRow).map(val => {
          let value = val instanceof moment ? val.format(fmtStr) : val
          value = value.replace(/"/g, '""').replace(/,/g, ';').replace(/\n{1,2}/g, ' | ')
          return value
        }).join(', ')
      }
      const csvHeader = this.warehouseColumns.map(column => column.label)
      const sortSeriesData = this.sortFn(this.warehouseData) //  Sort the data based on serverTime
      const seriesData = Object.values(sortSeriesData).map(mapFN)
      exportAsCSV(csvHeader, seriesData, 'Warehouse.csv')
    },
    downloadChartData: function () {
      /**
       * Get column headers and ids from selected columns adding in the second column
       * to give timestamp value
       * Note: first two columns are both timestamps, but second is more accurate
       * Map only the requrired columns from warehouse data and send to export function
       */
      const columnIds = ['cell_1', ...this.selectedColumns.map(fc => fc.id)]
      const csvHeader = ['timestamp', ...this.selectedColumns.map(fc => fc.label)]
      const exprtFmt = this.$store.state.configuration.csvExportDateFormat
      const mapFN = function (whDataRow) {
        let data = []
        columnIds.forEach((colId) => {
          let val = whDataRow[1][colId]
          if (colId === 'cell_0' || colId === 'cell_1') { val = moment(val).format(exprtFmt) } //  Convert timestamp to date format
          val = val.replace(/"/g, '""').replace(/,/g, ';').replace(/\n{1,2}/g, ' | ')
          data.push(val)
        })
        return data.join(', ')
      }
      const sortSeriesData = this.sortFn(this.warehouseData) //  Sort the data based on serverTime
      const seriesData = Object.entries(sortSeriesData).map(mapFN)
      exportAsCSV(csvHeader, seriesData, 'Warehouse-Chart.csv')
    },
    fetchMessages (startDate, endDate) {
      this.isDisabled = true
      const params = {
        deviceId: this.macId,
        pktType: this.$route.query.pktType,
        msgType: this.$route.query.msgType,
        startingDate: startDate,
        where: ` serverTime > "${startDate}" AND serverTime < "${endDate}"`
      }
      if (this.$route.query.msgIndex) {
        params.where += ` AND CAST(substring(data,21,2) AS SIGNED) = ${this.$route.query.msgIndex}`
        params.msgIndex = this.$route.query.msgIndex
      }
      /**
       * Convert number to decimal format
       * Check value is number, if not, return it otherwise return rounded value
       * @param {value} String/Number
       * @returns {value} - return the final value
       */
      const formatNumber = function (value) {
        return isNaN(value) ? value : round(value, 1)
      }
      this.$store.dispatch('devices/fetchMessages', params).then(response => {
        // If the warehouse columns have not been defined, use the first row of values to build the column headers
        if (this.warehouseColumns.length === 0 && response[0]) {
          this.warehouseColumns = Object.keys(response[0]).map((header, i) => {
            return {'label': header, 'id': `cell_${i}`, 'color': '', 'axis': -1, indx: i, formatter: formatNumber}
          })
        }
        // Find any new data from response
        let csvFileData = Object.values(response).map((row) => {
          var rObj = {}
          const regex = /^[\w]{3}\s[\w]{3}\s.+[\d]{4}$/
          // const fmtStr = 'M/D/YY HH:mm'
          const fmtStr = `${getDateFormat()} HH:mm`
          Object.values(row).forEach((cell, j) => {
            // If cell matches date pattern returned from database, reformat to shorter date value
            rObj[`cell_${j}`] = regex.test(cell) ? moment(cell, 'ddd MMM D HH:mm:ss YYYY').format(fmtStr) : cell
          })
          return rObj
        })
        if (response.length !== 0) this.csvFileData = this.sortFn(this.csvFileData.concat(csvFileData))
        this.isDisabled = false
      }).catch(e => {
        this.isDisabled = false
        console.log(e)
      })
    },
    fetchMessageList () {
      if (this.isDisabled) {
        return
      }
      // let startDate = moment(this.range[0], 'M-DD-YY')
      let startDate = moment(this.range[0])
      let endDate = moment(this.dateRanges.startDate, 'M-DD-YY')
      let dateDiff = startDate.diff(endDate, 'days')
      if (dateDiff < 0) {
        console.log('Getting new data')
        this.fetchMessages(moment(this.range[0]).format('YYYY-MM-DD'), moment(this.dateRanges.startDate, 'MM-DD-YY').format('YYYY-MM-DD HH:mm:ss'))
        this.dateRanges.startDate = moment(this.range[0]).format('M-DD-YY')
        this.dateRanges.endDate = moment(this.range[1]).format('M-DD-YY')
      } else {
        console.log('Data is available')
      }
    },
    handleResize () {
      this.window.width = `${window.innerWidth - 50}px`
      this.window.height = `${window.innerHeight}px`
    },
    openReportModal (reportKey) {
      // Opens the report modal for the incoming report key
      this.reportKey = reportKey
      // Get the external controls for the given report key
      // `${reportKey}ExternalControls` - Computed property assigned for the report key
      this.externalControls = this[`${reportKey}ExternalControls`] ? this[`${reportKey}ExternalControls`] : {}
      this.openModal('reportConfig')
    },
    previewReport (reportData) {
      // Opens the report preview modal
      // Assigns the preview table data from the response for the selected report
      this.reportData = reportData.data
      this.reportFileName = reportData.fileName
      // Open results modal
      this.modalIsActive.reportResults = true
    },
    /**
     * Sort the data based on serverTime
     * @param {data} Array - Warehouse list data
     * Return the sort data
     */
    sortFn (data) {
      return data.sort((a, b) => (Number(moment(a['cell_0']).format('X')) < Number(moment(b['cell_0']).format('X')) ? 1 : -1))
    },
    toggleAxis (row) {
      const colors = ['#d67777', '#4f99b4', '#82DFD6', '#0799b4', '#22D3D6']
      const rowId = this.warehouseColumns.findIndex(r => { return r.label === row.label })
      if (this.warehouseColumns[rowId].axis === -1) {
        this.warehouseColumns[rowId].axis += 1
        this.warehouseColumns[rowId].color = colors[this.colorIndex % colors.length]
        this.colorIndex += 1
      } else if (this.warehouseColumns[rowId].axis === 0) {
        this.warehouseColumns[rowId].axis += 1
      } else {
        this.warehouseColumns[rowId].axis = -1
        this.warehouseColumns[rowId].color = ''
      }
    },
    setSelectedColumns (columns) {
      this.selectedColumns = columns
    },
    updateRange: function (val) {
      // console.log('Slider: ', val)
      this.range = val
      this.fetchMessageList()
    }
  },
  mixins: [localStorageMixin, sessionManager, modalWindowManager]
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.roundCircle {
  padding: 10px;
  width: 10px;
  border-radius: 50%;
}
.chart-style {
  height: 275px;
  float: none;
  clear: both;
}
</style>
