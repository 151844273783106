<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <intelyt-modal
      :isActive="isActive"
      :title="title"
      v-on:close="close()"
    >
      <section class="modal-card-body">
        <div class="content">
          <form id="report">
            <div class="field-group">
              <div class="field" v-for="ctrl in selectedReport.controlList" :key="ctrl">
                <label class="label" v-if="controls[ctrl].type !== 'hidden'">{{controls[ctrl].label}}</label>
                <div class="control" v-if="controls[ctrl].type === 'input'">
                  <ValidationProvider :name="controls[ctrl].label" :rules="controls[ctrl].validate.rules"  v-slot="{ errors }">
                    <input class="input" :class="{'input': true}" type="text" v-model="queryInput[controls[ctrl].model]" @input="onChangeValidate()" :name="controls[ctrl].model">
                    <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="control" v-if="controls[ctrl].type === 'select'">
                  <div class="select">
                    <ValidationProvider :name="controls[ctrl].label" :rules="controls[ctrl].validate.rules"  v-slot="{ errors }">
                      <select v-model="queryInput[controls[ctrl].model]">
                        <option value="">{{`Select ${controls[ctrl].label}`}}</option>
                        <option v-for="opt in controls[ctrl].opts" :value="opt.val" :key="opt.key">{{opt.key}}</option>
                      </select>
                      <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="control" v-if="controls[ctrl].type === 'date'">
                  <ValidationProvider :name="controls[ctrl].label" :rules="controls[ctrl].validate.rules"  v-slot="{ errors }">
                    <datepicker v-model="queryInput[controls[ctrl].model]" :name="ctrl" :disabledDate="controls[ctrl].disabledDates" @input="onChangeValidate()"></datepicker>
                    <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="control" v-if="controls[ctrl].type === 'checkboxes'">
                  <ValidationProvider :name="controls[ctrl].label" :rules="controls[ctrl].validate.rules"  v-slot="{ errors }">
                    <template  v-for="(opt, index) in controls[ctrl].opts">
                      <input :key="index" type="checkbox" :value="opt.val" v-model="queryInput[controls[ctrl].model]">
                      <label :key="`label-${index}`" :for="opt.val"> {{opt.key}} &nbsp;</label>
                      <br :key="`br-${index}`" >
                    </template>
                    <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <!-- Add filename selector to all forms -->
              <div class="field">
                <label class="label">Output Filename</label>
                <ValidationProvider name="Output Filename" :rules="{required: true, max: 75,  regex: /^[a-zA-Z0-9 ._-]+$/,  isValidFileFormat: true}"  v-slot="{ errors }">
                  <input class="input" :class="{'input': true}" type="text" v-model="fileName" name="file_name">
                  <span v-show="errors[0]" class="help is-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="field">
                <label class="label">Output Format</label>
                <div class="control">
                  <label class="radio" v-for="(exportfile, idx) in outputformat" :key="idx">
                    <input type="radio" v-model="selectedFileFormat" :value="idx" name="reportFormat" data-vv-as="output format" @change="onChangeValidate()">
                    <span class="is-uppercase">{{idx}}</span> File
                  </label>
                </div>
              </div>
              <p v-if="notifyMsg !== ''" v-html="notifyMsg"></p>
            </div>
          </form>
        </div>
      </section>
      <template slot="footer">
        <a class="button is-primary" @click="previewReport()" v-if="!invalid && !preview && selectedFileFormat !== '' && !disableDwnBtn">Preview</a>
        <a class="button is-primary" @click="downloadAdvancedReport()" v-if="!invalid && disableDwnBtn === false && !preview">Download</a>
        <a class="button" @click="close()">Close</a>
      </template>
    </intelyt-modal>
  </ValidationObserver>
</template>

<script>
// import {extend} from 'vee-validate'
import { extend } from 'vee-validate/dist/vee-validate.full'
import IntelytModal from '@/components/IntelytModal'
import { mapActions, mapGetters } from 'vuex'
import { getMessageObject } from '../../src/store/helpers'
import Datepicker from '@/components/Datepicker'
import moment from 'moment-timezone'

export default {
  components: {
    IntelytModal,
    Datepicker
  },
  computed: {
    cleanInput: function () {
      /* This computed property calculates the values for the various inputs
       * and provides any formatting necessary.
       * @todo - Change datetime condition to look for string 'dateOnly' and
       *  add any other formatters necessary
       */
      const mapFn = ctrl => {
        if (this.controls[ctrl].format) {
          const dt = this.queryInput[ctrl] ? moment(this.queryInput[ctrl]).format('YYYY-MM-DD') : ''
          return dt
        } else {
          const inputVal = this.queryInput[ctrl]
          return (typeof inputVal === 'undefined' || inputVal === null) ? '' : inputVal
        }
      }
      return this.selectedReport.controlList.map(mapFn)
    },
    /* isValidFileFormat: function () {
      console.log(`/^(w|s|-)+.${this.selectedFileFormat}$/`)
      return `/^(w|s|-)+.${this.selectedFileFormat}$/`
    }, */
    cleanWhereClause: function () {
      const controlList = this.cleanInput
      let str = this.selectedReport.dataSource.config.whereClause
      str = str.replace(/%(\d+)/g, function (matched) {
        return controlList[matched.replace('%', '')]
      })
      return str
    },
    outputformat: function () {
      return this.selectedReport.outputs
    },
    selectAsset: function () {
      return Object.keys(this.assets)
    },
    title: function () {
      // Set the report filname with date value
      const tmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const suffixDateFmt = this.$store.state.company.reportDateFmt || 'MM-DD-YYYY'
      const dateNow = moment.utc().tz(tmzn).format(suffixDateFmt)
      this.setFileName(`${this.selectedReport.name}-${dateNow}.${this.selectedFileFormat}`)
      return this.selectedReport ? `Run Report - ${this.selectedReport.name}` : 'Report Configuration'
    }
  },
  data () {
    const companyIdStr = this.$store.state.company.id.toString()
    const locTypeOptions = [{key: 'Cell', val: 'Cell'}, {key: 'GPS', val: 'GPS'}, {key: 'iGate', val: 'iGate'}]
    return {
      companyIdStr,
      controls: {
        alertEvent: {label: 'Alert Event', model: 'alertEvent', type: 'select', opts: [], validate: {rules: {required: true}}},
        alertEventAll: {label: 'Alert Event', model: 'alertEventAll', type: 'select', opts: [], validate: {rules: {required: true}}},
        assetText: {label: 'Asset (S/N)', model: 'assetText', type: 'input', validate: {rules: {}}},
        batteryLevel: {label: 'Battery Level', model: 'batteryLevel', type: 'select', opts: [], validate: {rules: {required: true}}},
        shipmentId: {label: 'Shipment ID', model: 'shipmentId', type: 'input', validate: {rules: { required: true, regex: /^[a-zA-Z0-9-_ ]+$/ }}},
        shipmentIdDup: {label: 'Shipment ID', model: 'shipmentIdDup', type: 'hidden', validate: {rules: {}}},
        shipmentType: {label: 'Type', model: 'shipmentType', type: 'select', opts: [], validate: {rules: {}}},
        companyId: {label: 'CI', model: 'ci', type: 'hidden', value: companyIdStr, validate: {rules: {}}},
        cell: {label: 'Cell', model: 'cell', type: 'hidden', value: 'Cell', validate: {rules: {}}},
        gps: {label: 'GPS', model: 'gps', type: 'hidden', value: 'GPS', validate: {rules: {}}},
        deviceMacId: {label: 'Device MAC ID', model: 'deviceMacId', type: 'input', validate: {rules: {required: true}}},
        deviceType: {label: 'Device Type', model: 'deviceType', type: 'select', opts: [], validate: {rules: {required: true}}},
        endDate: {label: 'End Date', model: 'endDate', type: 'date', format: 'dateOnly', validate: {rules: {required: true, afterEqualDate: 'endDate,startDate,Start Date'}}},
        fileType: {label: 'Output Type', model: 'fileType', type: 'select', opts: this.fileTypeOptions(), validate: {rules: {}}},
        guid: {label: 'GUID', model: 'guid', type: 'input', validate: {rules: {required: true}}},
        idleDays: {label: 'Idle (Days)', model: 'idleDays', type: 'input', opts: [], validate: {rules: {required: true, numeric: true}}},
        locTypes: {label: 'Location Type', model: 'locTypes', type: 'checkboxes', opts: locTypeOptions, validate: {rules: {required: true}}},
        lookbackDays: {label: 'Lookback (Days)', model: 'lookbackDays', type: 'select', opts: [], validate: {rules: {}}},
        lookbackDaysInput: {label: 'Lookback (Days)', model: 'lookbackDaysInput', type: 'input', validate: {rules: {required: true, numeric: true}}},
        lookbackHours: {label: 'Lookback (Hours)', model: 'lookbackHours', type: 'select', opts: [], validate: {rules: {}}},
        startDate: {label: 'Start Date', model: 'startDate', type: 'date', format: 'dateOnly', validate: {rules: {required: true, beforeEqualDate: 'startDate,endDate,End Date'}}},
        origin: {label: 'Origin', model: 'origin', type: 'select', opts: [], validate: {rules: {required: true}}},
        originList: {label: 'Origin', model: 'originList', type: 'select', opts: [], validate: {rules: {}}},
        partNumber: {label: 'Part Number (12NC)', model: 'partNumber', type: 'input', validate: {rules: {required: true, regex: /^[0-9. ]+$/}}},
        warehouse: {label: 'Warehouse Name', model: 'warehouse', type: 'select', opts: [], validate: {rules: {required: true}}},
        warehouseUnit: {label: 'Warehouse Name', model: 'warehouseUnit', type: 'select', opts: [], validate: {rules: {required: true}}},
        zoneList: {label: 'Zone', model: 'zoneList', type: 'select', opts: [], validate: {rules: {}}}
      },
      disableDwnBtn: false,
      fileName: '',
      fileType: 'type',
      preview: false,
      queryInput: {},
      selectedFileFormat: '',
      reportName: 'sample',
      todayDate: moment().format('YYYY-MM-DD'),
      notifyMsg: ''
    }
  },
  created () {
    let vm = this
    extend('afterEqualDate', {
      message (field, {inputs}) {
        const obj = inputs.split(',')
        if (vm.queryInput[obj[1]]) {
          return `The ${field} must be after or equal to ${obj[2]}.`
        }
        return `The ${field} must be after or equal to ${obj[2]}.`
      },
      params: ['inputs'],
      validate: (value, {inputs}) => new Promise(resolve => {
        const obj = inputs.split(',')
        let datediff = moment(value).diff(vm.queryInput[obj[1]], 'days')
        // If the validation field available in the selected report control List
        // Then validate and return the value based on condition
        // Else return true
        const valid = vm.selectedReport.controlList.includes(obj[1]) ? datediff >= 0 : true
        resolve({
          valid 
        })
      })
    })
    extend('beforeEqualDate', {
      message (field, {inputs}) {
        const obj = inputs.split(',')
        if (vm.queryInput[obj[1]]) {
          return `The ${field} must be before or equal to ${obj[2]}.`
        }
      },
      params: ['inputs'],
      validate: (value, {inputs}) => new Promise(resolve => {
        const obj = inputs.split(',')
        let datediff = moment(value).diff(vm.queryInput[obj[1]], 'days')
        resolve({
          valid: datediff <= 0
        })
      })
    })
    extend('isValidFileFormat', {
      message () {
        return (vm.selectedFileFormat !== '') ? `File name must end in ${vm.selectedFileFormat}` : 'Please select a output filename format'
      },
      validate: () => new Promise(resolve => {
        const extension = vm.fileName.split('.').pop()
        const fileRegx = /^[\w,\s-]+\.[A-Za-z]{3}$/gm
        resolve({
          valid: fileRegx.test(vm.fileName) && extension === vm.selectedFileFormat
        })
      })
    })
    // get the messages for the activation page
    this.$store.dispatch('getPageMessage', 'reports')
  },
  methods: {
    close () {
      this.resetQueryInput()
      this.$emit('close')
    },
    /***
     * downloadAdvancedReport - This method used to prepare the report date for the
     * multiple sections configured in the report configuration.
     */
    downloadAdvancedReport () {
      const vm = this
      const preview = vm.preview
      vm.queryInput.shipmentIdDup = vm.queryInput.shipmentId
      // const rootState = {...vm.$store.state}
      this.$refs.observer.validate().then((result) => {
        if (result) {
          const reportKey = vm.selectedReport.reportKey
          // Get the message value from messages.json
          const dwnldMsg = getMessageObject(vm.$store.state, 'reports', 'download').message
          vm.notifyMsg = dwnldMsg
          vm.disableDwnBtn = true
          const config = {...vm.selectedReport}
          /* // Add fileName & fileFormat to the configuration so they are avaialble for 'runReport'
          config.fileName = vm.fileName
          config.fileFormat = this.selectedFileFormat */
          const fileName = vm.fileName
          const fileFormat = this.selectedFileFormat
          // Build controlValues from input values as object with names & values
          const inputValues = vm.cleanInput
          const controlValues = {}
          inputValues.forEach((value, index) => {
            const key = config.controlList[index]
            controlValues[key] = value
          })
          // generate report from reports module runReport
          // vm['reports/runReport']({config, controlValues, preview, reportKey, fileName, fileFormat}).then((response) => {
          vm['reports/runReport']({controlValues, preview, reportKey, fileName, fileFormat}).then((response) => {
            // If the response object have msg property then display it.
            // The msg property is used to display the no record found message for now
            if (response.msg) {
              vm.notifyMsg = response.msg
              // When no records found msg received, then enable the action buttons
              vm.disableDwnBtn = false
              vm.preview = false
            }
            if (response.close) {
              // If the current request is for preview then call the generate method
              // to open the report detail modal. The response object will have the
              // data for the preview modal
              if (preview && fileFormat === 'csv') {
                vm.$emit('generate', response)
              }
              // Close the current config modal, if the file is downloaded
              // or the preview response received
              vm.$emit('close')
            }

            if (response.generate) {
              // Get the message value from messages.json
              const buildMsg = getMessageObject(vm.$store.state, 'reports', 'build').message
              vm.notifyMsg = buildMsg
              vm.$store.dispatch('generateJsReport', response.data).then(() => {
                vm.disableDwnBtn = false
                vm.preview = false
                vm.$emit('close')
              })
            }
          }).catch(e => {
            vm.notifyMsg = `Error while building report: </br> ${e}`
            vm.disableDwnBtn = false
            vm.preview = false
          })
        }
      })
    },
    fileTypeOptions: function () {
      const out = ['csv', 'pdf', 'xls']
      return out
    },
    getAssetList: function () {
      return Object.keys(this.$store.state.assets)
    },
    resetQueryInput () {
      // console.log('Reset Query Input', this.$store.state.company.id)
      const ci = this.$store.state.company.id
      let location = (this.controls.origin.opts[0]) ? this.controls.origin.opts[0].val : ''
      this.disableDwnBtn = false
      this.preview = false
      this.selectedFileFormat = ''
      const tmzn = this.$store.state.user.timezone ? this.$store.state.user.timezone : moment.tz.guess()
      const suffixDateFmt = this.$store.state.company.reportDateFmt || 'MM-DD-YYYY'
      const dateNow = moment.utc().tz(tmzn).format(suffixDateFmt)
      this.fileName = `${this.selectedReport.name}-${dateNow}.${this.selectedFileFormat}`
      this.queryInput = {
        asset: null,
        batteryLevel: '',
        companyId: String(ci),
        endDate: this.todayDate,
        fileType: null,
        startDate: moment(this.todayDate).subtract('30', 'days').format('YYYY-MM-DD'),
        alertEvent: '',
        cell: 'Cell',
        gps: 'GPS',
        alertEventAll: '',
        locTypes: ['Cell', 'GPS', 'iGate'],
        originList: '',
        assetText: '',
        partNumber: '',
        shipmentId: '',
        deviceType: '',
        shipmentType: '0',
        origin: location,
        lookbackDays: this.selectedReport.lookbackOptsList ? this.selectedReport.lookbackOptsList[0] : '30',
        idleDays: '0',
        lookbackHours: this.selectedReport.lookbackHoursOptsList ? this.selectedReport.lookbackHoursOptsList[0] : '1',
        warehouse: '',
        warehouseUnit: '',
        zoneList: 'All'
      }
      this.$refs.observer.reset()
      this.notifyMsg = ''
    },
    previewReport () {
      // const vm = this
      this.preview = true
      this.downloadAdvancedReport()
    },
    setDefaultValues () {
      // If external controls not return early
      if (!this.externalControls) {
        return
      }
      // If external controls found means, get the controls then
      // override or add attributes to the actual control
      Object.keys(this.externalControls).forEach((control) => {
        // Override or add attributes to the existing control from the external control
        this.controls[control] = Object.assign({}, this.controls[control], this.externalControls[control])
        // If value attribute is found in the external control, then apply the
        // value to the queryInput object
        // if (this.externalControls[control].hasOwnProperty('value')) {
        if (Object.prototype.hasOwnProperty.call(this.externalControls[control], 'value')) {
          this.queryInput[control] = this.externalControls[control].value
        }
      })
    },
    /**
     * setFieldOptions - In this method, get the all field values from the state
     * or static values and apply it to the repective fields, before assigning the default values
     * from the props.
     */
    setFieldOptions () {
      // Set alert field options
      const allAlerts = this.$store.state.configuration.alertEvent
      const alertFilters = this.$store.state.configuration.alertFilters
      let alertEvents = alertFilters.map((alert) => { return {key: allAlerts[alert].name, val: String(alert)} })
      this.controls.alertEvent.opts = alertEvents

      // Set all alert event options
      const allEventValue = alertEvents.map(opt => opt.val).join(',')
      const allAlertOpts = [{key: 'All', val: allEventValue}, ...alertEvents]
      this.controls.alertEventAll.opts = allAlertOpts

      // Battery Level options
      let batLvlOptsList = [{key: '100%', val: '5000'}, {key: '75%', val: '4000'}, {key: '50%', val: '3500'}, {key: '10%', val: '3250'}]
      if (this.selectedReport.batLvlOptsList) batLvlOptsList = [...this.selectedReport.batLvlOptsList]
      const batLvlOpts = batLvlOptsList.map(x => { return {key: x.key, val: x.val} })
      this.controls.batteryLevel.opts = batLvlOpts

      // Device Type options
      let devTypeOptsList = [{key: 'iTag', val: '1'}, {key: 'iChime', val: '2'}]
      if (this.selectedReport.devTypeOptsList) devTypeOptsList = [...this.selectedReport.devTypeOptsList]
      const devTypeOpts = devTypeOptsList.map(x => { return {key: x.key, val: x.val} })
      this.controls.deviceType.opts = devTypeOpts
      
      // Look back days options
      let lookbackOptsList = ['7', '30', '60', '90', '180', '365']
      if (this.selectedReport.lookbackOptsList) lookbackOptsList = [...this.selectedReport.lookbackOptsList]
      const lookbackOpts = lookbackOptsList.map(x => { return {key: x, val: x} })
      this.controls.lookbackDays.opts = lookbackOpts

      // Look back days options
      let lookbackHoursOptsList = ['1', '4', '12', '24', '48', '96']
      if (this.selectedReport.lookbackHoursOptsList) lookbackHoursOptsList = [...this.selectedReport.lookbackHoursOptsList]
      const lookbackHoursOpts = lookbackHoursOptsList.map(x => { return {key: x, val: x} })
      this.controls.lookbackHours.opts = lookbackHoursOpts

      // Set Origin field options
      let locations = Object.values(this.$store.state.locations.all)
      locations = locations.map(loc => { return {key: loc.name, val: loc.name} })
      this.controls.origin.opts = locations

      // Set Origin List option
      const allLocations = [{key: 'All', val: '%'}, ...locations]
      this.controls.originList.opts = allLocations
      
      // Set Zone List option
      const zones = this.$store.state.geofences.zones.map(z => { return {key: z, val: z} })
      const zoneList = [{key: 'All', val: 'All'}, ...zones]
      this.controls.zoneList.opts = zoneList

      // Set shipment type options
      const shipmentTypes = [{key: 'Shipment', val: '0'}, {key: 'Reposition', val: '2'}]
      this.controls.shipmentType.opts = shipmentTypes

      // Set warehouse field options
      const warehouseOpts = Object.values(this.$store.state.warehouses.all).map(wh => { return {key: wh.name, val: wh.name} })
      this.controls.warehouse.opts = warehouseOpts

      // Set warehouse unit options
      const warehouseUnitOpts = []
      Object.values(this.$store.state.warehouses.all).forEach((wh) => {
        const macId = wh.macId
        wh.dhUnits.forEach((unit) => {
          const key = `${wh.name} | ${unit.model} | ${unit.id}`
          const value = `${macId}|${unit.msgType}|${unit.pktType}|${unit.index || 0}`
          warehouseUnitOpts.push({key: key, val: value})
        })
      })
      this.controls.warehouseUnit.opts = warehouseUnitOpts

      // Set the default value for the all alert event field
      this.queryInput.alertEventAll = allEventValue

      // Set the default value for the all location field
      this.queryInput.originList = '%'
      // Set default values to the control values
      this.setDefaultValues()

      // dateConfig is configured in the report configuration
      /* const dateConfig = {
        'startDate': 30
        // 'endDate': '2022-05-01'
        // 'endDate': 'feature'
      } */
      // dateConfig - contains disable date and default date configs
      const dateConfig = this.selectedReport.dateConfig || {}
      const disableDatesConfig = dateConfig.disableDates || {}
      const defaultDatesConfig = dateConfig.defaultDates || {}

      const getDefaultDate = function (field) {
        // Get the default date value from the configuration
        let dateString = defaultDatesConfig[field]
        // If the config value value is a number, then convert it to date string value
        if (typeof dateString === 'number') {
          dateString = moment().subtract(dateString, 'days').format('YYYY-MM-DD')
        }
        return dateString
      }
      // moment(this.todayDate).subtract('30', 'days').format('YYYY-MM-DD')
      // disableDate - Used to disable the date value based on the
      // start/end date field configuration in the selected report
      const disableDate = dateKey => (time) => {
        // If the given field name have the date disable value, then disable the dates
        // from the give date value. If it is a number, the calculate the
        // date for the give number and disable the dates before that
        if (Object.prototype.hasOwnProperty.call(disableDatesConfig, dateKey)) {
          let dateString = disableDatesConfig[dateKey]
          if (typeof dateString === 'number') {
            dateString = moment().subtract(dateString, 'days').format('YYYY-MM-DD')
          }
          const startDate = new Date(dateString)
          if (time < startDate) { // disable fields, if the date is less than 2019-01-01
            return true
          }
        }
        // @TODO The time value coming from the date picker component. The current date is not able to pick for some time zones.
        // So we added the 1440 minutes to get the current date. At some point it may allow us to select the future date
        // also. Need to fix this bug later.
        let datediff = moment().utc().diff(time, 'minutes') + 1440
        return (datediff < 0)
      }
      // If the selected report have start date control, then implement disable dates functionality for the start date
      if (this.selectedReport.controlList.includes('startDate')) {
        this.controls.startDate.disabledDates = disableDate('startDate')
        // If default startDate value found in config, then apply it
        if (Object.prototype.hasOwnProperty.call(defaultDatesConfig, 'startDate')) {
          this.queryInput.startDate = getDefaultDate('startDate')
        }
      }
      // If the selected report have end date control, then implement disable dates functionality for the end date
      if (this.selectedReport.controlList.includes('endDate')) {
        this.controls.endDate.disabledDates = disableDate('endDate')
        // If default endDate value found in config, then apply it
        if (Object.prototype.hasOwnProperty.call(defaultDatesConfig, 'endDate')) {
          this.queryInput.endDate = getDefaultDate('endDate')
        }
      }
    },
    onChangeValidate () {
      this.$refs.observer.validate()
    },
    setFileName (name) {
      this.fileName = name
    },
    ...mapActions([
      'fetchReportResults',
      'reports/runReport'
    ]),
    ...mapGetters([
      'reports/getPayload',
      'reports/getReportRequestList'
    ])
  },
  mounted () {
    this.resetQueryInput()
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    selectedReport: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // The external controls are passed from the external pages
    externalControls: {
      type: [Boolean, Object],
      default: false
    }
  },
  watch: {
    'selectedReport': {
      handler: function () {
        this.resetQueryInput()
      },
      deep: true
    },
    isActive: function (newValue) {
      if (newValue) {
        this.resetQueryInput()
        this.setFieldOptions()
        this.selectedFileFormat = this.selectedReport.outputs && Object.keys(this.selectedReport.outputs).length === 1 ? Object.keys(this.selectedReport.outputs)[0] : ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#report .select {
  vertical-align: unset;
}
</style>
